import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import '../assets/styles/pages/common-text-page.scss';

const AdditionalGuidelinesPage = () => (
  <Layout>
    <SEO title="Additional Guidelines" />
    <div className="common-text-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-9 mx-auto">
            <div className="c-h1 text-center">Additional Guidelines</div>
            <iframe
              title="Additional Guidelines"
              width="100%"
              height="100%"
              // src="https://drive.google.com/file/d/1D__6y0TIqRkXsFw7etBnKiHvNCxlVA68/preview?usp=sharing"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default AdditionalGuidelinesPage;
